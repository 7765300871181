
import { Options, Vue } from "vue-class-component";
import { ElMessageBox } from "element-plus";

@Options({
  components: {},
  props: {
    formDataSetup: {},
    option: "",
  },
})
export default class OrderOptionChild extends Vue {
  inputVisible = false;
  inputValue = "";
  inputVisibleId = false;
  inputValueId = null;
  listMetafields = [] as any;
  metafieldItem = {
    key: "",
    value: "",
    namespace: "global",
    value_type: "string",
  };
  hideAddNewBtn = false;
  options = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  rules = {
    key: [
      {
        required: true,
        message: "Please input order metafield key",
        trigger: "blur",
      },
    ],
  };
  async submitForm() {
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    if (this.metafieldItem.value === "") {
      this.metafieldItem.value = "null";
    }
    this.$emit("pushMetafield", this.metafieldItem);
    this.hideAddNewBtn = !this.hideAddNewBtn;
    this.metafieldItem = {
      key: "",
      value: "",
      namespace: "global",
      value_type: "string",
    };
  }
  handleClose(tag: any) {
    this.$emit("removeSiteName", tag);
  }

  handleCloseId(id: any) {
    this.$emit("removeDistributionCenterID", id);
  }

  showInput() {
    this.inputVisible = true;
  }
  showInputId() {
    this.inputVisibleId = true;
  }

  handleInputConfirm() {
    const inputValue = this.inputValue;
    if (inputValue) {
      this.$emit("pushSiteName", this.inputValue);
    }
    this.inputVisible = false;
    this.inputValue = "";
  }
  handleInputIdConfirm(id: any) {
    if (id || id === 0 || id === "0") {
      this.$emit("pushDistributionCenterID", id);
    }
    this.inputVisibleId = false;
    this.inputValueId = null;
  }
  clickDeleteMetafield(item: any) {
    ElMessageBox.confirm("Do you really want to delete ?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        this.$emit("removeMetafield", item);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
